/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.row {
  max-width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}
.gradient-body {
  background: rgb(224,243,230);
  background: radial-gradient(circle, rgba(224,243,230,1) 0%, rgba(169,199,226,1) 100%);
}
